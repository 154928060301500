import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';

let componentToRender;

if (process.env.REACT_APP_TYPE === 'mobile') {
  const MobileApp = React.lazy(() => import('./mobileApp'));
  componentToRender = <MobileApp />;
}
else if (process.env.REACT_APP_TYPE === 'RegisterOrg') {
  // const OnBoardAppIndex = React.lazy(() => import('./onBoard/index'));
  const OnBoardAppIndex = React.lazy(() => import('./newonboard/index'));
  componentToRender = <OnBoardAppIndex />;
} else {
  const AppToAdmin = React.lazy(() => import('./appToAdmin'));
  require('./index.css');
  componentToRender = <AppToAdmin />;
}

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#26abbf',
          fontSize: 14,
          wireframe: true,
        },
      }}
    >
      <BrowserRouter>
        {componentToRender}
      </BrowserRouter>
    </ConfigProvider>
  </React.StrictMode>
);
